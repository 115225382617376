<template>
  <FlotoCrudContainer
    ref="list"
    :fetch-fn="getAuditItems"
    :scrollable="false"
    :resource-name="$t('audit_trail')"
  >
    <template v-slot:add-controls="{ refreshList, items }">
      <MRow class="items-center" :gutter="0">
        <div class="flex flex-1 items-center">
          <FlotoForm
            ref="formRef"
            class="flex items-center"
            @submit="refreshList"
          >
            <MCol auto-size class="pr-2">
              <FlotoFormItem
                id="from-picker"
                :label="$t('from')"
                :rules="{
                  // eslint-disable-next-line
                  max_date: endDate,
                }"
              >
                <FlotoDatePicker
                  v-model="startDate"
                  :allow-clear="false"
                  :max-date="endDate"
                />
              </FlotoFormItem>
            </MCol>
            <MCol auto-size class="px-2">
              <FlotoFormItem
                id="to-picker"
                :rules="{
                  // eslint-disable-next-line
                  min_date: startDate,
                }"
                :label="$t('to')"
              >
                <FlotoDatePicker
                  v-model="endDate"
                  :allow-clear="false"
                  :min-date="startDate"
                />
              </FlotoFormItem>
            </MCol>

            <template v-slot:submit>
              <span />
            </template>
          </FlotoForm>

          <MButton
            id="search-btn"
            class="mt-2 ml-3"
            :loading="searchLoading"
            @click="submitForm"
          >
            {{ $t('search') }}
          </MButton>
        </div>
        <MCol class="text-right">
          <ExportButton
            v-if="(items || []).length > 0"
            :button-text="$t('download')"
            class="mx-1"
            :header="`${$t('download')}`"
            :file-name="`${$tc(moduleName)} ${$t('audit_trail')}`"
            :options="[
              { text: $t('pdf'), value: 'pdf' },
              { text: $t('excel'), value: 'excel' },
              { text: $t('csv'), value: 'csv' },
            ]"
            :default-data="{ exportFormat: 'pdf' }"
            :download-fn="handleDownload"
          >
            <template v-slot:trigger>
              <MTooltip>
                <template v-slot:trigger>
                  <MButton
                    shape="circle"
                    variant="neutral-lighter"
                    :shadow="false"
                  >
                    <MIcon name="download" />
                  </MButton>
                </template>
                {{ $tc('download') }}
              </MTooltip>
            </template>
          </ExportButton>
        </MCol>
        <MDivider />
      </MRow>
    </template>
    <template v-slot:list-items="{ items }">
      <MRow>
        <MCol class="my-2">
          <MTimeline mode="left" class="pl-2">
            <Audit
              v-for="timeline in items"
              :key="timeline.id"
              :parent-id="resourceId"
              :module-name="moduleName"
              v-bind="timeline"
            />
          </MTimeline>
        </MCol>
      </MRow>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import { getAuditApi } from './api'
import Audit from './audit'
import Moment from 'moment'
import ExportButton from '@modules/reports/components/export-button'
import { getEncryptedPassword } from '@utils/password'
import { downloadApi } from '@src/modules/reports/reports-api'
import Pick from 'lodash/pick'
import { PreferenceComputed } from '@state/modules/preference'
export default {
  name: 'AuditContainer',
  components: { Audit, ExportButton },
  props: {
    resourceId: { type: Number, required: true },
    moduleName: { type: String, required: true },
  },
  data() {
    return {
      searchLoading: false,
      startDate: Moment().subtract(6, 'months').startOf('day').unix() * 1000,
      endDate: Moment().endOf('day').unix() * 1000,
    }
  },
  computed: {
    ...Pick(PreferenceComputed, ['attachmentPassword']),
  },
  methods: {
    getAuditItems(limit, offset) {
      this.searchLoading = true
      return getAuditApi(
        {
          startDate: this.startDate,
          endDate: this.endDate,
          ...(this.applyComponentTypeFilter
            ? { componentType: this.componentType }
            : {}),
          ...(this.applyDiscoveryMethodFilter
            ? { discoveryMethod: this.discoveryMethod }
            : {}),
        },
        this.moduleName,
        this.resourceId,
        limit,
        offset
      ).finally(() => (this.searchLoading = false))
    },
    refresh() {
      this.$refs.list.refresh()
    },
    submitForm() {
      if (this.searchLoading) {
        return
      }
      this.$refs.formRef.submit()
    },
    getAvailableAttachmentPassword(data) {
      const availableAttachmentPassword = data.protectedPassword
        ? getEncryptedPassword(data.protectedPassword)
        : getEncryptedPassword(this.attachmentPassword)
      return data.enabled ? availableAttachmentPassword : ''
    },
    handleDownload(data) {
      const requestData = {}
      requestData.model = this.$constants.AUDIT
      requestData.exportFormat = data.exportFormat
      requestData.enabled = data.enabled
      requestData.attachmentPassword = this.getAvailableAttachmentPassword(data)
      requestData.auditExportDataRest = {
        refModel: this.moduleName,
        refId: this.resourceId,
        startDate: this.startDate,
        endDate: this.endDate,
      }
      return downloadApi(this.moduleName, requestData)
    },
  },
}
</script>
